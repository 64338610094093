<template>
	<div class="text-center full-screen ">
		<div class="font-weight-semibold">
			<p class="mg-t-0 pd-t-4">Join at <span class="font-40 mg-l-4">exsim.lffintech.co.th/join </span>with</p>
			<p class="mg-t-0">Game PIN: <b class="font-60 mg-l-4">{{room.roomNumber}}</b></p>
			<hr>
		</div>
		<div>
			<el-row>
				<el-col :span="24">
					<h2 class="font-60 mg-b-4"><span class="color-danger">Ex</span><span class="color-primary">SiM</span></h2>
				</el-col>
				<el-col :span="6">
					<p class="font-90 mg-b-less mg-t-3 font-weight-bold">{{room.groups.length}}/{{room.maxPlayer}}</p>
					<p class="font-60 mg-t-less font-weight-semibold">Team</p>
				</el-col>
				<el-col :span="15">
					<el-row>
						<el-col :span="12" v-for="(data,index) in room.groups" :key="index" class="pd-x-1 mg-b-4">
							<el-tag
							class="text-over w-100 font-20 animated rubberBand is-round"
							type="success"
							closable
							:hit="true"
							@close="kickPlayer(data._id,data.name)">
							{{index+1}}. {{data.name}}
							</el-tag>
						</el-col>
						<el-col :span="20" v-if="room.groups.length == 0">
							<el-alert
								type="warning"
								:center="true"
								:closable="false">
								<h1><i class="el-icon-loading"></i> Waiting for players...</h1>
							</el-alert>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</div>
		<el-row class="is-flex js-center">
			<el-col :span="6">
				<el-button @click="startGame()"  class="mg-y-5 w-100" type="primary" round :disabled="room.groups.length == 0">Start</el-button>
			</el-col>
		</el-row>
	</div>
</template>
<script>

import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
	export default{
		computed:{
			room(){
				return this.$store.state.room
			}
		},
		data(){
			return{
				team:['Team A','Team B','Team C'],
				groups:[]
			}
		},
		mounted(){
			this.fetchData()
			this.onLobby()
		},
		methods:{
			onLobby(){
			    this.$socket.client.on('resGetLobby',(res)=>{

					if(res.success){
						if(this.room.roomNumber == res.obj.roomNumber){
							res.obj.groups
							let data ={
								label:'groups',
								value:res.obj.groups
							}
							this.$store.commit('SET_ROOMDATA',data)
						}
					}
			    });
			},
			fetchData(){
				HTTP.get('/groups/'+this.room.roomNumber).then(res=>{

					if(res.data.success){
						let data ={
							label:'groups',
							value:res.data.obj.groups
						}
						this.$store.commit('SET_ROOMDATA',data)
					}
				})
			},
			kickPlayer(id,name){
	
				HTTP.delete('/join/room/'+this.room.roomNumber+"/"+id).then(res=>{
		
					if(res.data.success){
						this.$socket.client.emit('getLobby',{roomNumber:this.room.roomNumber})
						this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
					}
				}).finally(()=>{
					HTTP.get('/groups/'+this.room.roomNumber).then(res=>{
						if(res.data.success){
							let data ={
								label:'groups',
								value:res.data.obj.groups
							}

							this.$store.commit('SET_ROOMDATA',data)
						}
					})
				}).catch(e=>{
					console.log('/join/room/ error',e)
					this.alertFailError()
				})
			},
			async startGame(){
				this.room.current = 'Sale Order'
				await this.$store.dispatch('updateCurrent',this.room)
				await this.$socket.client.emit('getRoom',{roomNumber:this.room.roomNumber})
				this.$router.push('/payment-term').catch(err => {})
			}
		}
	}
</script>